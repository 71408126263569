/*****************************************
* CLASSES DO CARTÃO DO USUÁRIO DO HEADER
******************************************/
.vs-header-user-btn {
    background-color: #FFFFFF;
    color: #FAC102 !important;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

    .vs-header-user-btn:hover, .vs-header-user-btn:focus {
        background-color: #FFFFFF;
        border-color: #FAC102 !important;
        border: 1px solid #FAC102 !important;
    }
 
.vs-header-user-card-column_one {
    width: 300px;
}

.vs-header-user-card-column_two {
    width: 250px;
}

.vs-header-user-card {
    display: block;
    
    background-color: #FFFFFF;
    height: 150px;
    width: 400px;
    overflow: hidden;
    transition-duration: 2s;
    border-radius: 5px;
    top: 65px;
    justify-content: end;
}

    .vs-header-user-card img {
        border: 8px solid white;
        z-index: 100;
        height: 250px;
        border-radius: 240px;
        margin-top: -200px;
        margin-left: 270px;
        transition-duration: 1.5s;
        right: 0px;
        overflow: hidden;
    }

    .vs-header-user-card:hover img {
        margin-left: 200px;
    }

.vs-header-user-card-text {
    font-family: 'Roboto', sans-serif;
    margin-left: 10px;
    margin-top: 5px;
    font-size: 24px;
}
    .vs-header-user-card-text p {
        margin-bottom: 10px;
    }

    .vs-header-user-card-text i {
        margin-left: 3px;
        color: grey;
    }

.vs-header-user-card-footer {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    background-color: #FAC102;
    color: white;
    font-size: 25px;
    padding-top: 5px;
    padding-left: 20px;
    height: 50px;
    width: 400px;
    margin-top: 20px;
}
    .vs-header-user-card-footer input {
        color: #FFFFFF !important;
    }

    .vs-header-user-card-footer input:hover, .vs-header-user-card-footer input:focus {
        background-color: #FAC102;
        border-color: #FFFFFF !important;
        border: 1px solid #FFFFFF !important;
    }

.vs-header-user-card-line {
    border: 1px solid gray;
    width: 220px;
    margin-bottom: 15px;
    border-radius: 1px;
}

* {
    list-style: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

/*****************************************
* CLASSES DOS BOTÕES
******************************************/
.vs-btn {
    color: white !important;
    background-color: #FAC102 !important;
}

/*****************************************
* CLASSES DOS LINKS
******************************************/
.vs-card-link {
    color: black !important;
    font-size: 16px;
}
    .vs-card-link:hover, .vs-card-link:focus {
        font-weight: bold;
    }